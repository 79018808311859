import request from '@/utils/request'

export function getRotationList(){
    return request({
        url: '/appreq/rotation/list',
        method: 'get',
        headers: {
            isToken: false
        },
    })
}
