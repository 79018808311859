<template>
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list
        v-if="!isEmpty"
        v-model:loading="loading"
        :finished="finished"
        :finished-text="finishedText"
        @load="onLoad"
        class="container"
    >
      <div class="container_item" @click="toAnnounceDetails(item.noticeId)" v-for="item in list" :key="item">
        <div class="container_item_title">
          <div class="container_item_title_left">
            <img src="../../assets/images/announce/notice_read.png" alt="">
            <div class="container_item_title_left_name">
              {{item.noticeTitle}}
            </div>
          </div>
          <div class="container_item_title_right">
            <div class="container_item_title_right_name">
              {{item.createTime}}
            </div>
            <img src="../../assets/images/announce/right.png" alt="">
          </div>
        </div>
        <div class="container_item_line"></div>
      </div>

    </van-list>
  </van-pull-refresh>
  <van-empty
      description="暂无信息"
      v-if="isEmpty"
  />
</template>

<script setup>

import {ref} from "vue";
import {useRouter} from "vue-router";
import {Toast} from "vant";
import {getNoticeList} from "@/api/home";
const router = useRouter();
const  toAnnounceDetails=(id)=> {
  router.push({
    path: "/announceDetails",
    query: {
      id
    },
  });
}

const list = ref([]);
const loading = ref(false);
const refreshing = ref(false);
const finished = ref(false);
// const allchecked = ref([]);
// const checkboxGroup = ref(null);
const query=ref({})
const isEmpty = ref(false);
const finishedText = ref('');
import { useStore } from 'vuex';
const store = useStore();
query.value= {pageNum: 1,pageSize: 10,noticeType:"2",stationId:store.state.stationId}

const onRefresh = () => {
  query.value.pageNum = 1
  // 清空列表数据
  list.value = []
  finished.value = true
  finishedText.value = ''
  refreshing.value = true;
  onLoad();
};
const onLoad = async () => {
  loading.value = true
  finished.value = false
  refreshing.value = false
  let result = await getNoticeList(query.value);
  if (result?.code === 200) {
    loading.value = false;
    list.value = result?.rows;
    finished.value = true;
    isEmpty.value = false;
    if (list.value.length < result?.total) {
      query.value.pageNum++;
    }
  } else if (result?.code === 410) {
    console.error("执行了");
    loading.value = false;
    list.value = [];
    Toast("未查询到相关信息");
    finished.value = true;
    isEmpty.value = true;
    finishedText.value = ''
  } else {
    loading.value = false;
    finished.value = true;
    isEmpty.value = true;
    finishedText.value = ''
  }
};
</script>

<style lang="scss" scoped>
.container {
  background-color: rgba(255, 255, 255, 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  height: calc(100vh - 50px)!important;
  overflow-y: scroll;
  &_item {
    margin-top: 20px;
    width: 100%;

    &_title {
      display: flex;
      align-items: center;

      &_left {
        flex: 1;
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
        }

        &_name {
          margin-left: 10px;
          line-height: 20px;
          color: rgba(33, 33, 33, 100);
          font-size: 14px;
          text-align: left;
          font-family: PingFangSC-regular;
        }
      }

      &_right {
        display: flex;
        align-items: center;

        &_name {
          line-height: 20px;
          color: rgba(189, 189, 189, 100);
          font-size: 10px;
          text-align: right;
          font-family: PingFangSC-regular;
        }

        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    &_line {
      margin-top: 20px;
      height: 1px;
      line-height: 20px;
      background-color: rgba(250, 250, 250, 100);
      text-align: center;
    }
  }
}

</style>
