<template>
<div class="container">
  <div class="container_title">
    {{title}}
  </div>
  <div class="container_content" v-html="content">
  </div>
</div>
</template>

<script setup>
import {useRoute} from "vue-router";
import {getNewsDetail} from "@/api/news";
import {ref} from "vue";
const route = useRoute();
const id = route.query.id;
console.log(id);
const title = ref(null);
const content = ref(null);

const getDetail= async ()=>{
  let response=await getNewsDetail(id);
  console.error(response.data);
  title.value = response.data.noticeTitle;
  content.value = response.data.noticeContent;
/*  activity.value=response.data
  activity.value.starttime=activity.value.starttime.replace('-','年');
  activity.value.starttime=activity.value.starttime.replace('-','月')+'日';
  activity.value.endtime=activity.value.endtime.replace('-','年');
  activity.value.endtime=activity.value.endtime.replace('-','月')+'日';*/
}
getDetail();
</script>

<style lang="scss" scoped>
.container{
  background-color: rgba(255, 255, 255, 100);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 15px 20px 15px;
  &_title{
    color: rgba(51, 51, 51, 100);
    font-size: 18px;
    text-align: justify;
    font-family: PingFangSC-semiBold;
    line-height: 30px;
    font-weight: bolder;
  }
  &_content{
    margin-top: 20px;
    line-height: 30px;
    color: rgba(66, 66, 66, 100);
    font-size: 14px;
    text-align: justify;
    font-family: PingFangSC-regular;
  }
}
</style>
