<template>
<div class="container">
<div class="container_title">{{title}}</div>
<div class="container_time">{{time}}</div>
<div class="container_content" v-html="content"></div>
</div>
</template>

<script setup>
import {useRoute} from "vue-router";
import {getNewsDetail} from "@/api/news";
import {ref} from "vue";
const route = useRoute();
const id = route.query.id;
const title = ref(null);
const content = ref(null);
const time = ref(null);
const getDetail= async ()=>{
  let response=await getNewsDetail(id);
  title.value = response.data.noticeTitle;
  content.value = response.data.noticeContent;
  time.value = response.data.createTime;
}
getDetail();
</script>

<style lang="scss" scoped>
.container{
  height: calc(100vh - 80px) !important;
  overflow-y: scroll;
  background-color: rgba(255, 255, 255, 100);
  padding: 20px 15px 20px 15px;
  &_title{
    line-height: 30px;
    color: rgba(51, 51, 51, 100);
    font-size: 18px;
    text-align: justify;
    font-family: PingFangSC-semiBold;
    font-weight: bolder;
    /*换行*/
    word-wrap: break-word;
    word-break: break-all;
  }
  &_time{
    margin-top: 15px;
    margin-bottom: 30px;
    line-height: 14px;
    color: rgba(66, 66, 66, 100);
    font-size: 14px;
    text-align: left;
    font-family: PingFangSC-regular;
  }
  &_content{
    line-height: 30px;
    color: rgba(66, 66, 66, 100);
    font-size: 14px;
    text-align: justify;
    font-family: PingFangSC-regular;
  }
}
</style>
