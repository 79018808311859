<template>

  <van-dropdown-menu >
    <van-dropdown-item v-model="value1" :options="option1" @change="handelChange" />
    <van-dropdown-item v-model="value2" :options="option2" @change="handelCityChange"/>
  </van-dropdown-menu>

  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="container"
    >
      <div class="container_item" v-for="(item,index) in list"
           :key="index" @click="toStationDetails(item.id)">
        <div class="container_item_left" >
          <img :src="item.imgUrl?item.imgUrl:pic" alt="">
        </div>
        <div class="container_item_right">
          <div class="container_item_right_name">
            {{item.name}}
          </div>
          <div class="container_item_right_address">
            {{item.address}}
          </div>
        </div>
      </div>
    </van-list>
  </van-pull-refresh>
  <van-empty
      description="暂无信息"
      v-if="isEmpty"
  />
</template>

<script setup>
import {ref} from "vue";
import {useRouter} from "vue-router";
import {Toast} from "vant";
import {getStationList,getProArea,getCityArea} from "@/api/station";
//活动默认图片
const pic = ref('../../assets/images/station/station_index.png');
const isEmpty = ref(false)
const value1 = ref("100000");
const value2 = ref(100000);
const option1 = ref([]);
const option2 = ref([{text:"全国",value:100000}]);
const finalCityCode = ref();
const router = useRouter();
const  toStationDetails=(id)=> {
  router.push({
    path: "/stationDetails",
    query: {
      id
    },
  });
}

const list = ref([]);
const loading = ref(false);
const refreshing = ref(false);
const finished = ref(false);
const onRefresh = () => {
  refreshing.value = true;
  onLoad()
};
const query=ref({})
query.value= {pageNum: 1,pageSize: 10,cityId:finalCityCode}
const onLoad = async () => {
  if (refreshing.value) {
    list.value = [];
    refreshing.value = false;
  }
  let result = await getStationList(query.value);
  if (result?.code === 200) {
    loading.value = false;
    list.value = result?.rows;
    finished.value = true;
    isEmpty.value = false;
    if (list.value.length < result?.total) {
      query.value.pageNum++;
    }
  } else if (result?.code === 410) {
    loading.value = false;
    list.value = [];
    Toast("未查询到相关信息");
    finished.value = true;
    isEmpty.value = true;
  } else {
    loading.value = false;
    finished.value = true;
    isEmpty.value = true;
  }
};

const getProAreaData= async ()=>{
  let response= await getProArea();
  if(response.code === 200){
    option1.value = response.data;
    console.error(option1.value.length);
  }
};
getProAreaData();

//省级
const handelChange = (value)=>{
  if(value == 100000){
    option2.value = [{text:"全国",value:100000}];
    value2.value = 100000;
    finalCityCode.value = null;
    onRefresh();
  }else {
    getCityAreaData(value);
  }

};

const getCityAreaData= async (value)=>{
  const data = {parentId:value};
  let response= await getCityArea(data);
  if(response.code === 200){
    option2.value = response.data;
    if(option2.value.length > 0){
      value2.value = option2.value[0].value;
      finalCityCode.value = value2.value;
    }
    onRefresh();
  }
};

//市级
const handelCityChange = (value)=>{
  finalCityCode.value = value;
  onRefresh();
};
</script>

<style lang="scss" scoped>
::v-deep .van-dropdown-menu__bar{
  background-color: #EDEDED;
}
::v-deep .van-dropdown-menu__title:after{
  color: #3A3A3C;
}
.container{
  padding: 15px 10px 15px 10px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 90px) !important;
  overflow-y: scroll;
  &_item{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding: 10px 10px 5px 10px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    img{
      width: 120px;
      height: 80px;
      border-radius: 4px;
    }
    &_right{
      height: 80px;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &_name{
        margin-top: 3px;
        flex: 3;
        line-height: 14px;
        color: rgba(33, 33, 33, 100);
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-semiBold;
        font-weight: bolder;
      }
      &_address{
        flex: 2;
        line-height: 14px;
        color: rgba(189, 189, 189, 100);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }
  }
}
</style>
