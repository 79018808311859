//获取积分排名
import request from "@/utils/request";

export function getNewsDetail(id){
    return request({
        url: '/appreq/notice/'+id,
        headers: {
            isToken: false
        },
        method: 'get',
    })
}


export function getNews(params,data){
    return request({
        url: '/appreq/notice/list',
        headers: {
            isToken: false
        },
        method: 'get',
        data,
        params
    })
}
