<template>
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list
        v-if="!isEmpty"
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="container"
    >
      <div class="container_item" @click="toNewsDetails(item.noticeId)" v-for="(item,index) in list"
           :key="index">
        <img :src="item.imgUrl?item.imgUrl:pic" alt="">
        <div class="container_item_right">
          <div class="container_item_right_name">
            {{item.noticeTitle}}
          </div>
          <div class="container_item_right_time">
            {{item.createTime}}
          </div>
        </div>
      </div>
    </van-list>
  </van-pull-refresh>
  <van-empty
      description="暂无信息"
      v-if="isEmpty"
  />
</template>

<script setup>

import {ref} from "vue";
import {useRouter} from "vue-router";
import {Toast} from "vant";
import {getNews} from "@/api/news";
const router = useRouter();
const  toNewsDetails=(id)=> {
  router.push({
    path: "/newsDetails",
    query: {
      id
    },
  });
}

const list = ref([]);
/*const stationId = ref(null);*/
const loading = ref(false);
const refreshing = ref(false);
const finished = ref(false);
const isEmpty = ref(false)
const onRefresh = () => {
  finished.value = true;
  list.value = []
  refreshing.value = true;
  query.value.pageNum = 1;
  onLoad()
};
//新闻默认图片
const pic = ref('../../assets/images/activity/activity.png');
const query=ref({})
query.value= {pageNum: 1,pageSize: 10,noticeType:1}


const onLoad = () => {
  loading.value = true
  finished.value = false
  refreshing.value = false
  newList()
};


const newList = async () => {
  let result = await getNews(query.value);
  loading.value = false
  refreshing.value = false
  if (result.code === 200) {
    list.value = list.value.concat(result.rows)
    finished.value = false;
    isEmpty.value = false;
    query.value.pageNum++;
  } else if (result.code === 410) {
    if(query.value.pageNum==1){
      isEmpty.value = true;
    }else {
      finished.value = true
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  height: calc(100vh - 90px) !important;
  overflow-y: scroll;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &_item {
    width:100%;
    margin-bottom: 10px;
    line-height: 20px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 100);
    text-align: center;
    display: flex;
    align-items: center;
    padding: 15px 10px 15px 10px;
    img {
      width: 80px;
      height: 60px;
      border-radius: 4px;
    }
    &_right{
      margin-left: 10px;
      &_name{
        line-height: 18px;
        color: rgba(33, 33, 33, 100);
        font-size: 14px;
        text-align: justify;
        font-family: PingFangSC-regular;
      }
      &_time{
        margin-top: 13px;
        line-height: 10px;
        color: rgba(158, 158, 158, 100);
        font-size: 10px;
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }
  }
}
</style>
