<template>
<!--  <div id="container2"></div>-->
  <div class="container">
    <div class="container_notice">
      <div class="container_notice_leftpart">
        <div class="container_notice_leftpart_icon"><img
            src="../assets/images/home/notice.png"
            alt=""
          ></div>
        <div class="container_notice_leftpart_title" @click="toAnnounceDetails(announce.noticeId)">{{announce.noticeTitle}}</div>
      </div>
      <div class="container_notice_rightpart">
        <div class="container_notice_rightpart_divider"></div>
        <div class="container_notice_rightpart_icon" @click="toAnnounce"><img
            src="../assets/images/home/info.png"
            alt=""
          ></div>
      </div>
    </div>

    <div class="container_banner">
      <van-swipe :autoplay="3000" lazy-render>
      <van-swipe-item v-for="(image,index) in images" :key="index">
        <img :src="image.url"/>
      </van-swipe-item>
    </van-swipe>
    </div>
    <div class="container_guide">
      <div class="container_guide_top">
        <div class="container_guide_top_left">
          <div class="container_guide_top_left_icon"><img
              src="../assets/images/home/pos.png"
              alt=""
            ></div>
          <div class="container_guide_top_left_title" @click="chooseSation">{{stationName}}</div>
        </div>
        <div class="container_guide_top_right" @click="toScoreRank">
          <div class="container_guide_top_right_icon"><img
              src="../assets/images/home/score.png"
              alt=""
            ></div>
          <div class="container_guide_top_right_title">积分排行</div>
        </div>
      </div>
      <div class="container_guide_buttons">
        <div class="container_guide_buttons_btn"  v-for="(item,index) in actTypeList"
             :key="index" @click="toActivityType(item.activityTypeId)">
          <div class="container_guide_buttons_btn_icon"><img
              :src="item.icon?item.icon:actTypePic"
              alt=""
            ></div>
          <div class="container_guide_buttons_btn_title">{{item.name}}</div>
        </div>

        <div class="container_guide_buttons_btn" @click="toStation">
          <div class="container_guide_buttons_btn_icon"><img
              src="../assets/images/station/station.png"
              alt=""
            ></div>
          <div class="container_guide_buttons_btn_title">分站分布</div>
        </div>
      </div>
    </div>
    <div class="container_activity">
      <div class="container_activity_top">
        <div class="container_activity_top_left"><img
            src="../assets/images/home/act_icon.png"
            alt=""
          ><span class="container_activity_top_left_title">活动</span></div>
        <div class="container_activity_top_right">
          <span class="container_activity_top_right_title" @click="toActivityList">更多</span>
          <img
            src="../assets/images/home/more.png"
            alt=""
          >
        </div>
      </div>

      <div class="container_activity_divider">
        <van-divider></van-divider>
      </div>
      <!--活动列表-->
      <div class="container_activity_itemlist">
        <div
          class="container_activity_iteminfo"
          v-for="(item,index) in actList"
          :key="index"
          @click="toActivityDetail(item.id)"
        >
          <div class="container_activity_item">
            <div class="container_activity_item_pic">
              <img
                :src="item.picUrl?item.picUrl:actPic"
                alt=""
              >
            </div>
            <div class="container_activity_item_info">
              <div class="container_activity_item_info_title">{{item.name}}</div>
              <div class="container_activity_item_desc">
                <div class="container_activity_item_desc_left">已报名<span class="container_activity_item_desc_count">{{item.alreaySignUpNum}}</span>/{{item.limitNum}}</div>
                <div class="container_activity_item_desc_right">{{item.station ? item.station.name : "站点"}}</div>
              </div>
            </div>
          </div>
          <div
            class="container_activity_divider"
            v-if="index<actList.length-1"
          >
            <van-divider></van-divider>
          </div>

        </div>

      </div>

    </div>
    <!--新闻列表-->

    <div class="container_activity">
      <div class="container_activity_top">
        <div class="container_activity_top_left"><img
            src="../assets/images/home/news.png"
            alt=""
          ><span class="container_activity_top_left_title--news">新闻</span></div>
        <div class="container_activity_top_right" @click="toNews">
          <span class="container_activity_top_right_title">更多</span>
          <img
            src="../assets/images/home/more.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_activity_divider--news">
        <van-divider></van-divider>
      </div>
      <div class="container_activity_itemlist">
        <div
          class="container_activity_iteminfo"
          v-for="(item,index) in newsList"
          :key="index"
          @click="toNewsDetails(item.noticeId)"
        >
          <div class="container_newsitem">
            <div class="container_newsitem_pic">
              <img
                  :src="item.imgUrl?item.imgUrl:newPic"
                alt=""
              >
            </div>
            <div class="container_newsitem_info">
              <div class="container_newsitem_info_title">{{item.noticeTitle}}</div>
              <div class="container_newsitem_info_date">{{item.createTime}}</div>
            </div>

          </div>

        </div>
      </div>

    </div>
  <!--商品列表-->
    <div class="container_activity">
            <div class="container_activity_top">
        <div class="container_activity_top_left"><img
            src="../assets/images/home/mall.png"
            alt=""
          ><span class="container_activity_top_left_title--shop">商城</span></div>
        <div class="container_activity_top_right">
          <span class="container_activity_top_right_title" @click="toShopList">更多</span>
          <img
            src="../assets/images/home/more.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_activity_divider">
        <van-divider></van-divider>
      </div>
      <div class="container_hotgoods">
          <div class="container_hotgoods_leftpic" @click="toShopNew()">
            <img
            src="../assets/images/home/newgoods.png"
            alt=""
           />
          </div>
          <div class="container_hotgoods_rightpic" @click="toShopHot()">
           <img
            src="../assets/images/home/hotgoods.png"
            alt=""
           />
          </div>
      </div>
      <div class="container_activity_itemlist">
          <div class="container_activity_iteminfo"  v-for="(item,index) in goodsList"
          :key="index" @click="toShopDetail(item.id)">
               <div class="container_goodsitem">
                  <div class="container_goodsitem_pic">
                    <img
                      :src="item.images.length>0?item.images[0].url:goodsPic"
                      alt=""
                    >
                  </div>
                   <div class="container_goodsitem_info">
                    <div class="container_goodsitem_info_title">{{item.name}}</div>
                    <div class="container_goodsitem_info_desc">
                        <div class="container_goodsitem_info_desc_price"><span class="container_goodsitem_info_desc_nowprice">&yen;{{item.discountPrice}}</span> <span class="container_goodsitem_info_desc_originprice">&yen;{{item.originalPrice}}</span> </div>
                        <div class="container_goodsitem_info_desc_btn"><van-button plain round type="warning" size="mini">立即抢购</van-button></div>
                    </div>
                  </div>


               </div>
          </div>
      </div>



    </div>

  </div>


<!--  定位正常时的弹窗-->
  <VanDialog v-model:show="dialogShow" title="选择站点" show-cancel-button @confirm = "confirm" @cancel = "cancel">
    <div class="dialog_success_container"  >
      <van-radio-group v-model="checked">
          <div class="dialog_success_container_item">
              <div class="dialog_success_container_item_top">
                <div class="dialog_success_container_item_top_text">
                  距离您最近的站点:
                </div>
                <div class="dialog_success_container_item_top_info">
                  <div class="dialog_success_container_item_top_info_left">
                    <div class="dialog_success_container_item_top_info_left_name">{{nearStation.name}}</div>
                    <div class="dialog_success_container_item_top_info_left_dis">距您 {{nearStation.dis}} 米</div>
                  </div>
                  <div class="dialog_success_container_item_top_info_right">
                    <van-radio :name=nearStation.id></van-radio>
                  </div>
                </div>
            </div>
              <div class="dialog_success_container_item_line"></div>
              <div class="dialog_success_container_item_buttom">
              <div class="dialog_success_container_item_buttom_text">
                其他站点:
              </div>
              <div class="dialog_success_container_item_buttom_info" v-for="(item,index) in fastStationList" :key="index">
                <div class="dialog_success_container_item_buttom_info_left" >
                  <div class="dialog_success_container_item_buttom_info_left_name">{{item.name}}</div>
                  <div class="dialog_success_container_item_buttom_info_left_dis">距您 {{item.dis}} 米</div>
                </div>
                <div class="dialog_success_container_item_buttom_info_right">
                  <van-radio :name="item.id"></van-radio>
                </div>
              </div>
            </div>
          </div>


<!--        <div class="dialog_container_item" v-for="(item,index) in stationList" :key="index">-->
<!--          <div class="dialog_container_item_left">-->
<!--            <div class="dialog_container_item_left_text">-->
<!--              {{item.name}}-->
<!--            </div>-->
<!--            <div class="dialog_container_item_left_dis">-->
<!--              距您 {{item.dis}} 米-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="dialog_container_item_right">-->
<!--            <van-radio :name="item.id"></van-radio>-->
<!--          </div>-->
<!--        </div>-->
      </van-radio-group>


    </div>
  </VanDialog>


  <!--  定位失败时的弹窗-->
  <VanDialog v-model:show="stationShow" title="选择站点" show-cancel-button @confirm = "stationConfirm" @cancel = "stationCancel">
    <div class="dialog_container"  >
      <van-radio-group v-model="stationChecked">
        <div class="dialog_container_item" v-for="(item,index) in stationList" :key="index">
          <div class="dialog_container_item_left">
            <div class="dialog_container_item_left_text">
              {{item.name}}
            </div>
<!--            <div class="dialog_container_item_left_dis">
              距您 {{item.dis}} 米
            </div>-->
          </div>
          <div class="dialog_container_item_right">
            <van-radio :name="item.id"></van-radio>
          </div>
        </div>
      </van-radio-group>


    </div>
  </VanDialog>



</template>
<script setup name="Home">
import {nextTick, onMounted} from 'vue'
import { ref } from "@vue/reactivity";
import {useRouter} from "vue-router";
import {getActivityList,getNoticeList,getShopGoods,getActivityTypeList,getStationList} from "@/api/home";
import AMapLoader from '@amap/amap-jsapi-loader'
import {Dialog} from "vant";
import {getMainStationInfo} from "@/api/station";
import { useStore } from 'vuex';
import {getRotationList} from "@/api/rotation";
const store = useStore();
//const map = shallowRef(null)
const  amap = ref(null);
 const images = ref([])

//活动默认图片
const actPic = ref('../assets/images/home/more.png');
//新闻默认图片
const newPic = ref('../assets/images/home/act_news.png');
//商品默认图片
const goodsPic = ref('../assets/images/home/newgoods.png');
//活动类型默认图片
const actTypePic = ref('../assets/images/home/child.png');
const actList = ref([])
const newsList = ref([])
const goodsList = ref([])
const actTypeList = ref([])
//分站list
const stationList = ref();
//通知
const announce = ref([])
//分站id
const stationId  = ref("");
//分钟名称
const stationName = ref('');
//定位的坐标(当前位置)
const position = ref(null);
//分页
const query=ref({})
query.value= {pageNum: 1,pageSize: 3}
//是否显示弹窗（定位成功）
const dialogShow = ref(false);
//是否显示位置弹框（定位失败）
const stationShow = ref(false);
const VanDialog = Dialog.Component;
//选中的状态（定位成功）
const checked = ref("");
//选中的状态（定位失败）
const stationChecked = ref("");
//距离最近的站点信息
const nearStation = ref();
//距离远的站点信息
const fastStationList = ref([]);

//是否定位成功
const isPos = ref(null);

const router = useRouter();
const  toStation=()=> {
  router.push({
    path: "/station"
  });
}
const  toNews=()=> {
  router.push({
    path: "/news"
  });
}
const  toAnnounce=()=> {
  router.push({
    path: "/announce"
  });
}
const  toScoreRank=()=> {
  router.push({
    path: "/scoreRank"
  });
}
//公告
const  toAnnounceDetails=(id)=> {
  //console.error(id);
  if(id){
    router.push({
      path: "/announceDetails",
      query: {
        id
      },
    });
  }

}
//新闻
const  toNewsDetails=(id)=> {
  //console.error(id);
  router.push({
    path: "/newsDetails",
    query: {
      id
    },
  });
}
//活动更多
const  toActivityList=()=> {
  router.push({
    path: "/activityList"
  });
}
//跳转活动分类
const  toActivityType=(id)=> {
  router.push({
    path: "/activityList",
    query: {
      typId:id
    },
  });
}
//兑换热榜
const  toShopHot=()=> {
  router.push({
    path: "/shopHot"
  });
}
const  toShopNew=()=> {
  router.push({
    path: "/shopNew"
  });
}
const  toShopList=()=> {
  router.push({
    path: "/shopList"
  });
}

const  toActivityDetail=(id)=> {
  router.push({
    path: "/activityDetails",
    query: {
      id
    },
  });
}

const  toShopDetail=(id)=> {
  router.push({
    path: "/shopDetail",
    query: {
      id
    },
  });
}



//actList.value = [1, 2, 3]
//newsList.value = [1, 2, 3]
//goodsList.value = [1, 2, 3]

//获取轮播图列表
const rotationList = async ()=>{
  let response = await getRotationList();
  images.value=response.data
}
//获取活动列表
const activityList = async ()=>{
  let sid;
  if(stationId.value == 'undefined' || stationId.value == 'null' || stationId.value == null){
    sid ='';
  }else {
    sid = stationId.value;
  }
  let response = await getActivityList(query.value,{stationId:sid,isMain:"0",checkStatus:"1"});
  console.log(response.rows)
  if (response.rows.length > 0) {
    actList.value = response.rows
  } else {
    actList.value = [];
  }
}

//获得新闻列表
const getNewsList = async () => {
  query.value = {
    noticeType:"1",
    pageNum: 1,
    pageSize: 3
  }
  let response = await getNoticeList(query.value);
  if (response?.code === 200){
    if (response.rows.length > 0) {
      newsList.value = response.rows
    } else {
      newsList.value = [];
    }
  }else {
    newsList.value = [];
  }

}

//获得公告列表
const getAnnounceList = async () => {
  let sid;
  if(stationId.value == 'undefined' || stationId.value == 'null' || stationId.value == null){
    sid ='';
  }else {
    sid = stationId.value;
  }
  query.value = {
    noticeType:"2",
    stationId:sid,
    pageNum: 1,
    pageSize: 3
  }
  let response = await getNoticeList(query.value);
  if(response?.code === 200){
    if (response.rows.length > 0) {
      announce.value = response.rows[0];
    } else {
      announce.value.noticeTitle = '暂无公告';
    }
  }else {
    announce.value.noticeTitle = '暂无公告';
  }

}

//获得商品列表
const getGoodsList = async () => {
  let sid;
  if(stationId.value == 'undefined' || stationId.value == 'null' || stationId.value == null){
    sid ='';
  }else {
    sid = stationId.value;
  }
  query.value = {
    stationId:sid,
    pageNum: 1,
    pageSize: 3
  }
  let response = await getShopGoods(query.value);
  if(response.code === 200){
    if (response.rows.length > 0) {
      goodsList.value = response.rows
    } else {
      goodsList.value = [];
    }
  }else {
    goodsList.value = [];
  }

}

//获得活动分类列表
const getActTypeList = async () => {
  let response = await getActivityTypeList();
  if (response.rows.length > 0) {
    actTypeList.value = response.rows
  } else {
    actTypeList.value = [];
  }
}

//获得站点列表
const getStation = async () => {
  let response = await getStationList();
  if (response.rows.length > 0) {
    stationList.value = response.rows
  } else {
    stationList.value = [];
  }
}



const  ininMap =() =>
{
  AMapLoader.load({
    key: 'f59bd1414c7906ed19aadb98ee9ee086', // 设置您的key
    version: '2.0',
    plugins: [],
    AMapUI: {
      version: '1.1',
      plugins: []

    },
    Loca: {
      version: '2.0.0'
    }
  }).then((AMap) => {
    amap.value = AMap;
   /* console.log(AMap)
    map.value = new AMap.Map('container2', {
      viewMode: '3D',
      zoom: 5,
      zooms: [2, 22]
    })
    console.log(map)*/
    getLocation(AMap);
  }).catch(e => {
    console.log(e)
  })
}

//解析定位结果
const getLocation  =  (amap)=>{
  amap.plugin('AMap.Geolocation',  function() {
    var geolocation = new amap.Geolocation({
      // 是否使用高精度定位，默认：true
      enableHighAccuracy: true,
      // 设置定位超时时间，默认：无穷大
      timeout: 10000,
      // 定位按钮的停靠位置的偏移量
      offset: [10, 20],
      //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
      zoomToAccuracy: true,
      //  定位按钮的排放位置,  RB表示右下
      position: 'RB'
    })

    geolocation.getCurrentPosition(function(status,result){
      if(status=='complete'){
        isPos.value = true;
        onComplete(result)
      }else{
        onError(result)
        isPos.value = false;
        showStationDialog();
      }
    });

    function onComplete (data) {
      // data是具体的定位信息
      //console.error('data-->'+data.position.lng)
      position.value = data.position;
      getDis();
    }

    function onError (data) {
      // 定位出错
      console.error('data1111-->'+data.message)
    }
  })
}

//计算位置
const getDis = ()=>{
    if(stationList.value != null && stationList.value.length > 0) {
      var now = [position.value.lng, position.value.lat];
      for(var i = 0; i < stationList.value.length;i++){
        var station = [stationList.value[i].lon,stationList.value[i].lat];
        var dis = amap.value.GeometryUtil.distance(now, station);
        //console.error('dis-->'+dis);
        stationList.value[i].dis = dis.toString().substring(0,dis.toString().indexOf(".")+3);
      }
      sort();
      //处理显示的问题
      nearStation.value = stationList.value[0];
      for(var j = 1; j < stationList.value.length; j++){
        fastStationList.value.push(stationList.value[j]);
      }

    }
  showDialog();
}


//排序
const sort =() =>{
  for(var i =0 ; i< stationList.value.length-1;i++){
    for(var j = 0; j<stationList.value.length-1-i;j++){
      if(Number(stationList.value[j+1].dis) < Number(stationList.value[j].dis)){
        var tm = stationList.value[j+1];
        stationList.value[j+1] = stationList.value[j];
        stationList.value[j] = tm;
      }
    }
  }
}




//展示弹窗（定位成功）
const showDialog=()=>{
  if(store.state.stationId == null){
    if(stationList.value != null && stationList.value.length > 0) {
      checked.value = nearStation.value.id;
    }
    dialogShow.value = true;
  }

}
//dialog点击确定
const confirm=()=>{
  //localStorage.setItem("stationId",checked.value);
  store.commit("setStationIds",checked.value);
  if(stationList.value != null && stationList.value.length > 0){
    for(var i = 0; i < stationList.value.length; i++){
      if( stationList.value[i].id === checked.value){
        localStorage.setItem("stationName",stationList.value[i].name);
        break;
      }
    }
  }
  stationId.value = checked.value;
  if( localStorage.getItem("stationName") != null){
    stationName.value = localStorage.getItem("stationName");
  }
  rotationList();
  activityList();
  getNewsList();
  getGoodsList();
  getAnnounceList();
  getActTypeList();
}

//初始化数据
const initData = async()=>{

  if( store.state.stationId != null && store.state.stationId != 'undefined' && store.state.stationId != 'null'){
    stationId.value = store.state.stationId;
    stationName.value = localStorage.getItem("stationName");
  }else {
    let result = await getMainStationInfo();
    stationId.value = result.data.id;
    stationName.value = result.data.name;
  }
  console.log("ssssid==",store.state.stationId)
  rotationList();
  activityList();
  getNewsList();
  getGoodsList();
  getAnnounceList();
  getActTypeList();
}

//dialog点击了取消
const cancel=  ()=>{
  if(store.state.stationId == null){
    saveMainStation();
    activityList();
    rotationList();
    getNewsList();
    getGoodsList();
    getAnnounceList();
    getActTypeList();
  }

}


//展示弹窗(定位失败)
const showStationDialog=()=>{
   if(store.state.stationId == null){
     if(stationList.value != null && stationList.value.length > 0) {
       checked.value = stationList.value[0].id;
     }
     stationShow.value = true;
   }
}

//dialog(定位失败)点击确定
const stationConfirm=()=>{
  store.commit("setStationIds",stationChecked.value);
 // localStorage.setItem("stationId",stationChecked.value);
  if(stationList.value != null && stationList.value.length > 0){
    for(var i = 0; i < stationList.value.length; i++){
      if( stationList.value[i].id === stationChecked.value){
        localStorage.setItem("stationName",stationList.value[i].name);
        break;
      }
    }
  }
  stationId.value = stationChecked.value;
  if( localStorage.getItem("stationName") != null){
    stationName.value = localStorage.getItem("stationName");
  }
  rotationList();
  activityList();
  getNewsList();
  getGoodsList();
  getAnnounceList();
  getActTypeList();
}


//dialog点击了取消
const stationCancel=  ()=>{
  if(store.state.stationId == null){
    saveMainStation();
    rotationList();
    activityList();
    getNewsList();
    getGoodsList();
    getAnnounceList();
    getActTypeList();
  }
}




//保存主站信息
const saveMainStation =  async()=>{
  //将store存储的信息改为主站信息
  let result = await getMainStationInfo();
  if(result?.code === 200){
    store.commit("setStationIds",result.data.id);
    //localStorage.setItem("stationId",result.data.id);
    localStorage.setItem("stationName",result.data.name);
    stationId.value = checked.value;
    if( localStorage.getItem("stationName") != null){
      stationName.value = localStorage.getItem("stationName");
    }
  }
}

const chooseSation = ()=>{
  if(isPos.value){
    if(stationList.value != null && stationList.value.length > 0) {
      checked.value = nearStation.value.id;
    }
    dialogShow.value = true;
  }else {
    if(stationList.value != null && stationList.value.length > 0) {
      checked.value = stationList.value[0].id;
    }
    stationShow.value = true;
  }
}

onMounted(() => {
  nextTick(()=>{
    ininMap();
  })
})

initData();
getStation();

</script>

<style lang="scss" scoped>
#container2{
  width: 100%;
  height: 200px;
}
.container {
  height: calc(100vh - 106px) !important;
  overflow-y: scroll;
  //overflow-y: scroll;
  &_notice {
    background-color: rgba(255, 255, 255, 100);
    border-radius: 6px;
    height: 44px;
    line-height: 20px;
    margin: 10px 10px 10px 10px;
    display: flex;
    flex-direction: row;
    &_leftpart {
      height: 44px;
      line-height: 44px;
      display: flex;
      flex-direction: row;
      flex: 1;
      &_icon {
        line-height: 44px;
      }
      &_icon img {
        margin: 0 10px 0 10px;
        width: 18px;
        vertical-align: middle;
      }
      &_title {
        color: #212121;
      }
    }
    &_rightpart {
      &_icon {
        line-height: 44px;
      }
      &_icon img {
        margin: 0 10px 0 0;
        width: 18px;
        vertical-align: middle;
      }
    }
  }
  &_banner {
    margin: 10px 10px 20px 10px;
    height: 120px;
  }
  &_banner img {
    height: 120px;
    border-radius: 6px;
  }
  &_guide {
    margin: 0 10px 0 10px;
    border-radius: 6px 6px 0px 0px;
    background-color: rgba(255, 255, 255, 100);
    &_top {
      display: flex;
      flex-direction: row;
      height: 16px;
      justify-content: space-between;
      padding: 18px 10px 10px 10px;
      &_left {
        flex: 1;
        display: flex;
        flex-direction: row;
        &_icon {
          line-height: 16px;
        }
        &_icon img {
          width: 16px;
          margin-right: 5px;
          vertical-align: middle;
        }
      }
      &_right {
        display: flex;
        flex-direction: row;
        &_icon {
          line-height: 16px;
        }
        &_icon img {
          width: 16px;
          margin-right: 5px;
          vertical-align: middle;
        }
      }
    }
    &_buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 10px 25px 20px 25px;
      justify-content: flex-start;
      &_btn {
        margin-top: 24px;
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        &_title {
          margin-top: 10px;
          font-size: 12px;
          color: #212121;
        }
        &_icon img {
          width: 40px;
        }
      }
    }
  }

  &_activity {
    margin: 20px 10px 0 10px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    &_top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px 10px 0 10px;
      &_left {
        display: flex;
        flex-direction: row;
        align-items: center;
        &_title {
          font-size: 16px;
          color: #fc4956;
          font-weight: bold;
        }
        &_title--news {
          font-size: 16px;
          color: #3a9ced;
          font-weight: bold;
        }
        &_title--shop {
          font-size: 16px;
          color: #1DCE75;
          font-weight: bold;
        }
      }
      &_left img {
        vertical-align: middle;
        height: 20px;
        margin-right: 5px;
      }
      &_right {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      &_right img {
        vertical-align: middle;
        height: 12px;
      }
    }
    &_divider {
      padding: 0 10px 0 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      &--news {
        padding: 0 10px 0 10px;
        margin-top: 10px;
        margin-bottom: -10px;
      }
    }

    &_itemlist {
      padding: 0 10px 20px 10px;
    }
    &_iteminfo {
      display: flex;
      flex-direction: column;
    }
    &_item {
      display: flex;
      flex-direction: row;
      &_pic {
        width: 100px;;
        img {
          width: 100px;
          height: 80px;
        }
      }

      &_info {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        justify-content: space-between;
        &_title {
          font-size: 16px;
          color: #212121;
        }
      }
      &_desc {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &_left {
          font-size: 14px;
          color: #bdbdbd;
        }
        &_count {
          font-size: 14px;
          color: #ff9100;
        }
        &_right {
          padding-left: 5px;
          margin-top: 2px;
          font-size: 10px;
          color: #bdbdbd;
        }
      }
    }
  }

  &_newsitem {
    margin-top: 10px;
    padding: 15px 10px 15px 10px;
    border-radius: 5px;
    background-color: #fafafa;
    display: flex;
    flex-direction: row;
    &_pic {
      margin-right: 10px;
    }
    &_pic img {
      vertical-align: middle;
      width: 80px;
      height: 60px;
    }
    &_info {
      width: 100%;
      display: flex;
      flex-direction: column;
      &_title {
        font-size: 14px;
        color: #212121;
      }
      &_date {
        color: #9e9e9e;
        font-size: 10px;
        margin-top: 8px;
      }
    }
  }
  &_hotgoods{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10px 0 10px;
    margin-bottom: 5px;

    &_leftpic img{
      width: 160px;
      height: 90px;
    }

    &_rightpic img{
      width: 160px;
      height: 90px;
    }
  }
    &_goodsitem {
    margin-top: 10px;
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    background-color: #fafafa;
    display: flex;
    flex-direction: row;
    &_pic {
      margin-right: 10px;
    }
    &_pic img {
      vertical-align: middle;
      width: 80px;
      height: 80px;
    }
    &_info {
      width: 100%;
      line-height: 28px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &_title {
     /*   text-align: center;*/
        font-size: 14px;
        color: #212121;
      }
      &_date {
        color: #9e9e9e;
        font-size: 10px;
        margin-top: 8px;
      }
      &_desc{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &_price{
          flex:1;
        }
        &_nowprice{
          font-size: 16px;
          color: #FF9100;
        }
        &_originprice{
          font-size: 10px;
          color: #BDBDBD;
          text-decoration-line: line-through;
        }
        &_btn{
          line-height: 0;
          vertical-align: middle;
        }
      }
    }
  }
.van-button--warning{
  color: #FF9100;
}
}
.dialog_container{
  margin: 10px;
  &_item{
    display: flex;
    padding: 10px;
    font-size: 14px;

    &_left{
      flex:1;

      &_dis{
        margin-top: 5px;
        font-size: 14px;
        color: #bdbdbd;
      }

    }




  }

}

.dialog_success_container{
  margin: 10px;
  &_item{
    font-size: 14px;

    &_top{
      margin: 10px;
      &_info{
        margin-top:10px;
        display: flex;
        &_left{
          flex:1;
          &_dis{
            margin-top: 5px;
            font-size: 14px;
            color: #bdbdbd;
          }
        }
      }
    }
    &_line {
      margin-top: 10px;
      margin-bottom: 10px;
      border: 1px solid #f5f5f5;
      width: 100%;
    }
    &_buttom{
      margin: 10px;
      &_info{
        margin-top:10px;
        display: flex;
        &_left{
          flex:1;
          &_dis{
            margin-top: 5px;
            font-size: 14px;
            color: #bdbdbd;
          }
        }
      }
    }

    //&_dis{
    //  margin-top: 5px;
    //  font-size: 14px;
    //  color: #bdbdbd;
    //}
  }

}
.van-swipe-item img{
  width: 100%;
  height: 120px;
}
</style>

