<template>
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh" >
    <van-list
        v-if="!isEmpty"
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="container"
    >
      <div class="container_item" v-for="(item,index) in list"
           :key="index">
        <div class="container_item_left">
          <div :class="{'container_item_left_num':index < 3 ,'container_item_left_num2':index >=3 }">
            {{index+1}}
          </div>
          <img src="../../assets/images/activity/avatar.png" alt="">
          <div class="container_item_left_name" >
            {{item.username == null? "用户" :item.username}}
          </div>
        </div>
        <div class="container_item_right"> {{item.score}}</div>
      </div>
<!--      <div class="container_item">
        <div class="container_item_left">
          <div class="container_item_left_num2">
            2
          </div>
          <img src="../../assets/images/activity/avatar.png" alt="">
          <div class="container_item_left_name">
            用户998
          </div>
        </div>
        <div class="container_item_right">666</div>
      </div>-->
    </van-list>
  </van-pull-refresh>
  <van-empty
      description="暂无信息"
      v-if="isEmpty"
  />
</template>

<script setup>

import {ref} from "vue";
import {getUserListOrderByScore} from "@/api/home";

const isEmpty = ref(false)
const list = ref([]);
const loading = ref(false);
const refreshing = ref(false);
const finished = ref(false);
//分页
const query=ref({})
query.value= {pageNum: 1,pageSize: 10}
const onRefresh = () => {
  query.value.pageNum = 1;
  list.value = []
  finished.value = true;
  refreshing.value = true;
  onLoad()
};

const onLoad = () => {
  loading.value = true
  finished.value = false
  refreshing.value = false
  scoreList()
};
const scoreList = async ()=>{
  let result = await getUserListOrderByScore(query.value);
  loading.value = false
  refreshing.value = false
  if (result.code === 200) {
    list.value = list.value.concat(result.rows)
    finished.value = false;
    isEmpty.value = false;
    query.value.pageNum++;
  } else if (result.code === 410) {
    if(query.value.pageNum==1){
      isEmpty.value = true;
    }else {
      finished.value = true
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: calc(100vh - 90px)!important;
  overflow-y: scroll;
  padding: 15px 10px 15px 10px;

  &_item {
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    padding: 15px 15px 15px 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    &_left{
      height: 50px;
      position: relative;
      flex: 1;
      display: flex;
      align-items: center;
      &_num{
        line-height: 24px;
        color: rgba(255, 145, 0, 100);
        font-size: 20px;
        text-align: center;
        font-family: OPPOSans-bold;
      }
      &_num2{
        line-height: 24px;
        font-size: 20px;
        text-align: center;
        color: rgba(33, 33, 33, 100);
      }
      img{
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        left: 40px;
      }
      &_name{
        position: absolute;
        line-height: 16px;
        color: rgba(33, 33, 33, 100);
        font-size: 16px;
        text-align: justify;
        font-family: PingFangSC-regular;
        left: 100px;
      }
    }
    &_right{
      line-height: 16px;
      color: rgba(33, 33, 33, 100);
      font-size: 16px;
      text-align: left;
      font-family: PingFangSC-semiBold;
      font-weight: bolder;
    }
  }
}
</style>
