<template>
  <div class="container">
    <img   :src="pic" alt="">
    <div class="container_des">
      <div class="container_des_name">{{name}}</div>
      <div class="container_des_address">{{address}}</div>
      <div class="container_des_content" v-if="content">
        <div class="container_des_content_font">
          {{content}}
        </div>
      </div>
    </div>
  </div>
  <div class="bottom">
    <van-button @click="toMap">导航到分站</van-button>
    <van-button @click="confirmBtn">前往分站主页</van-button>
  </div>
</template>

<script setup>
//活动默认图片
import {ref} from "@vue/reactivity";
import {useRoute, useRouter} from "vue-router";
import {getStationById} from "@/api/station";
import { useStore } from 'vuex';
import AMapLoader from '@amap/amap-jsapi-loader'
import {Toast} from "vant";
const store = useStore();
const route = useRoute();
const id = route.query.id;
const name= ref('');
const address= ref('');
const content=ref('');
const pic=ref('../../assets/images/station/station_index.png');
const lat = ref(null);
const lon = ref(null);
const os = ref(null);
const router = useRouter();
const  amap = ref(null);
//当前位置
const position = ref(null);
const getDetail= async ()=>{
  let response= await getStationById(id);
  if(response.code === 200){
    name.value = response.data.name;
    address.value = response.data.address;
    content.value = response.data.description;
    pic.value = response.data.imgUrl;
    lat.value = response.data.lat;
    lon.value = response.data.lon;
  }
};
getDetail()

const confirmBtn=  ()=>{
  console.error(id);
  store.commit("setStationIds",id);
  localStorage.setItem("stationName", name.value);
  router.push({
    path: "/home",
  });
}

//导航
const toMap = ()=>{
  if(position == null){
    Toast("定位异常,不能进行此次导航");
  }
  if(os.value ==="IOS"){
    location.href = `https://uri.amap.com/navigation?from=`+position.value.lng+`,`+position.value.lat+`,当前位置&to=`+lon.value+`,`+lat.value+`,`+name.value+`,midwaypoint&mode=car&policy=1&src=mypage&coordinate=gaode&callnative=0`;
  }
  if (os.value === 'Android') {
    location.href = `https://uri.amap.com/navigation?from=`+position.value.lng+`,`+position.value.lat+`,当前位置&to=`+lon.value+`,`+lat.value+`,`+name.value+`,midwaypoint&mode=car&policy=1&src=mypage&coordinate=gaode&callnative=0`;
  }
}


const  ininMap =() =>
{
  AMapLoader.load({
    key: 'f59bd1414c7906ed19aadb98ee9ee086', // 设置您的key
    version: '2.0',
    plugins: [],
    AMapUI: {
      version: '1.1',
      plugins: []

    },
    Loca: {
      version: '2.0.0'
    }
  }).then((AMap) => {
    amap.value = AMap;
    /* console.log(AMap)
     map.value = new AMap.Map('container2', {
       viewMode: '3D',
       zoom: 5,
       zooms: [2, 22]
     })
     console.log(map)*/
    getLocation(AMap);
  }).catch(e => {
    console.log(e)
  })
}

//解析定位结果
const getLocation  =  (amap)=>{
  amap.plugin('AMap.Geolocation',  function() {
    var geolocation = new amap.Geolocation({
      // 是否使用高精度定位，默认：true
      enableHighAccuracy: true,
      // 设置定位超时时间，默认：无穷大
      timeout: 10000,
      // 定位按钮的停靠位置的偏移量
      offset: [10, 20],
      //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
      zoomToAccuracy: true,
      //  定位按钮的排放位置,  RB表示右下
      position: 'RB'
    })

    geolocation.getCurrentPosition(function(status,result){
      if(status=='complete'){
        onComplete(result)
      }else{
        onError(result)
      }
    });


    function onComplete (data) {
      // data是具体的定位信息
      console.error('data-->'+data.position.lng)
      position.value = data.position;

    }

    function onError (data) {
      // 定位出错
      console.error('data1111-->'+data.message)
    }
  })
}


//封装区分当前环境是ios端还是android端的方法
const runningPlatform =()=>{
  let ua = navigator.userAgent.toLowerCase()
  console.log('内核', ua);
  // ios
  if(ua.indexOf('like mac os x') > -1) {
    os.value = 'IOS'
  }
  // android
  if(ua.indexOf('android') > -1) {
    os.value = 'Android'
  }
}
runningPlatform();
ininMap();
</script>

<style lang="scss" scoped>

.container{
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px)!important;
  overflow-y: scroll;
  img{
    width: 100%;
    height: 240px;
  }
  &_des{
    padding: 15px 10px 15px 10px;
    &_name{
      line-height: 16px;
      color: rgba(33, 33, 33, 100);
      font-size: 16px;
      text-align: left;
      font-family: PingFangSC-semiBold;
      font-weight: bolder;
    }
    &_address{
      margin-top: 15px;
      line-height: 12px;
      color: rgba(158, 158, 158, 100);
      font-size: 12px;
      text-align: left;
      font-family: PingFangSC-regular;
    }
    &_content{
      padding: 15px 10px 15px 10px;
      margin-top: 15px;

      border-radius: 6px;
      background-color: rgba(255, 255, 255, 100);
      color: rgba(16, 16, 16, 100);
      font-size: 14px;
      text-align: center;
      font-family: Arial;
      &_font{
        line-height: 30px;
        color: rgba(66, 66, 66, 100);
        font-size: 14px;
        text-align: justify;
        font-family: PingFangSC-regular;
      }
    }
  }
}
.bottom {
 /* position: fixed;*/
  display: flex;
  bottom: 0;
  width: 100%;
  height: 64px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;

  .van-button {
    margin: 10px;
    width: 50%;
    height: 44px;
    border-radius: 6px;
    background-color: #FFBF1E;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 14px;
    font-family: PingFangSC-semiBold;
    font-weight: bolder;
  }
}
</style>
